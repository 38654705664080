<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 센터현황</h2>
                <p style="color:red;font-size:15px;">※ 예약상태가 [입실] 이고 상담구분값(예시: 일반상담 1회권)이 있어야 출력됩니다.</p>
            </div>
            <div class="con">
                <div class="con_table">
                    <select v-model="idxCrmCenter" @change="getCenterStatusList('condition')" class="font-16 w-150px h-40px">
                        <option value="-1">센터선택</option>
                        <option value="0">본원센터</option>
                        <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                    </select>
                    <div class="search2">
                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>기간 검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getCenterStatusList('condition')">Search<span></span></a>
                        </div>
                    </div>
                    <div>
                        <button type="button" @click="excelDown()" class="down-btn float-right">엑셀파일 다운로드</button>
                    </div>
                    <div class="default_tab">
                        <div class="tabs" @click="tabType = 'today', getCenterStatusList()" :class="{'active': tabType === 'today'}">
                            금일
                        </div>
                        <div class="tabs" @click="tabType = 'week', getCenterStatusList()" :class="{'active': tabType === 'week'}">
                            주간
                        </div>
                        <div class="tabs" @click="tabType = 'month', getCenterStatusList()" :class="{'active': tabType === 'month'}">
                            월간
                        </div>
                    </div>
                    <table class="default_table">
                        <tbody v-if="loading">
                            <tr>
                                <th>전체고객수</th>
                                <th>신규</th>
                                <th>진행</th>
                                <th>홀딩</th>
                                <th>종결</th>
                            </tr>
                            <tr>
                                <td colspan="5">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>전체고객수</th>
                                <th>신규</th>
                                <th>진행</th>
                                <th>홀딩</th>
                                <th>종결</th>
                            </tr>
                            <tr>
                                <td >{{aCnt}}</td>
                                <td>{{nCnt}}</td>
                                <td>{{pCnt}}</td>
                                <td>{{hCnt}}</td>
                                <td>{{eCnt}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <table class="default_table">
                        <tbody v-if="loading">
                            <tr>
                                <th rowspan="2">상담자명</th>
                                <th colspan="9">상담/검사 건수</th>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <th>일반</th>
                                <th>EAP</th>
                                <th>도박</th>
                                <th>범피</th>
                                <th>검사</th>
                                <th>소견서</th>
                                <th>기타</th>
                                <th>협약센터EAP</th>
                            </tr>
                            <tr>
                                <td colspan="10">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th rowspan="2">상담사명</th>
                                <th colspan="9">상담/검사 건수</th>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <th>일반</th>
                                <th>EAP</th>
                                <th>도박</th>
                                <th>범피</th>
                                <th>검사</th>
                                <th>소견서</th>
                                <th>기타</th>
                                <th>협약센터EAP</th>
                            </tr>

                            <tr v-for="(item, index) of reservationList" :key="index">
                                <td >{{item.staffName}}</td>
                                <td class="pointer" @click="setConsultProgress(item)">{{item.total}}</td>
                                <td>{{item.normal}}</td>
                                <td>{{item.eap}}</td>
                                <td>{{item.gamble}}</td>
                                <td>{{item.crime}}</td>
                                <td>{{item.test}}</td>
                                <td>{{item.opinion}}</td>
                                <td>{{item.etc}}</td>
                                <td>{{item.realEap}}</td>
                            </tr>
                            <tr v-if="reservationList.length === 0">
                                <td colspan="10">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </div>
    <consultProgress :onModal.sync="parentOnModal" 
                     :cousultStartDate.sync="parentConsultStDt"
                     :cousultEndDate.sync="parentConsultEDt"
                     :consultStaffName.sync="parentStaffNm"
                     :consultStaffId.sync="parentStaffId">
    </consultProgress>
</div>
</template>

<script>
import FileSaver from 'file-saver';
import consultProgress from '@/components/modal/consultProgress.vue';
import { DateToString } from '@/utils/common';
export default {
    components:{
        consultProgress
    },
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        tabType: 'today', // 탭 검색 (금일: today, 주간: week, 월간: month)

        idxCrmCenter: -1,

        centerList: [],
        clientCnt: '',
        reservationList: [],
        aCnt: 0,
        pCnt: 0,
        eCnt: 0,
        hCnt: 0,
        nCnt: 0,
        total: 0,
        normal: 0,
        gamble: 0,
        crime: 0,
        eap: 0,
        test: 0,
        opinion: 0,
        etc: 0,
        staffName: '',
        weekStartDate: '',
        weekEndDate: '',
        loading:true,

        parentOnModal: false, //부모에서 모달 visible/invisible
        parentConsultStDt: '', //부모에서 모달 상담시작일자
        parentConsultEDt: '',  //부모에서 모달 상담종료일자
        parentStaffNm: '', //부모에서 모달 상담사 이름
        parentStaffId: -1,  //부모에서 모달 상담사 id
    }),

    mounted() {
        this.getCenterListAll()
        this.getCenterStatusList()
    },

    watch:{
        startDate(newVal, oldVal){
            this.parentConsultStDt = DateToString(newVal); 
        },
        endDate(newVal, oldVal){
            this.parentConsultEDt = DateToString(newVal);
        }
    },

    methods: {
        excelDown() {

            //location.href = this.axios.root+"/api/v1/excel/center-status-list?endDate="+this.endDate+"&idxCrmCenter="+this.idxCrmCenter+"&startDate="+this.startDate;
            // 위에 처럼 하면 daterpicker에서 받은 값이 Nov 어쩌고 저쩌고 대한민국 어쩌고 라고 string으로 나오면서 date형식으로 foramt이 안됨
             var params = {
                startDate: this.startDate,
                endDate: this.endDate,
                idxCrmCenter: this.idxCrmCenter,
            }

            let headers = {
                'Content-Disposition': "attachment; filename=centerStatus_excel_template.xlsx",
                'Content-Type': 'ms-vnd/excel;charset=UTF-8'
            }

            this.axios.defaults.headers.post[headers]

            this.axios.get('/api/v1/excel/center-status-list', {
                    params: params,
                    responseType: 'arraybuffer'
                }). then(res => {
                    FileSaver.saveAs(new Blob([res.data]), "centerStatus_excel_template.xlsx")
                }) .catch(err => {
                    console.error(err)
                })
            
        },

        printWeek() {
            var now = new Date();
            var nowDayOfWeek = now.getDay();
            var nowDay = now.getDate();
            var nowMonth = now.getMonth();
            var nowYear = now.getYear();
            nowYear += (nowYear < 2000) ? 1900 : 0;
            this.weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
            this.weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
        }, 

        formatDate2(date) {
            var myYear = date.getFullYear();
            var mymonth = date.getMonth()+1;
            var myweekday = date.getDate();
            if (mymonth < 10) mymonth = '0' + mymonth;
            if (myweekday < 10) myweekday = '0' + myweekday;
            return (myYear+"-"+mymonth + "-" + myweekday);
        }, 

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        getCenterStatusList(type) {
            this.loading = true;
            if(type !== 'condition') {
                if(this.tabType === 'today') {
                    this.startDate = this.formatDate();
                    this.endDate = this.formatDate()
                } else if (this.tabType === 'week') {
                    this.printWeek()
                    this.startDate = this.formatDate2(this.weekStartDate) 
                    this.endDate = this.formatDate2(this.weekEndDate) 
                } else if (this.tabType === 'month') {
                    let now = new Date()
                    this.startDate = this.formatDate2(new Date(now.getFullYear(), now.getMonth(), 1))
                    this.endDate = this.formatDate2(new Date(now.getFullYear(), now.getMonth()+1, 0))
                } 
            }

            let params = {
                startDate: this.startDate,
                endDate: this.endDate,
                idxCrmCenter: this.idxCrmCenter,
            }

            this.axios.get('/api/v1/admin/center/status', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.clientCnt = res.data.clientCnt
                        this.aCnt = res.data.clientCnt.acnt
                        this.nCnt = res.data.clientCnt.ncnt
                        this.pCnt = res.data.clientCnt.pcnt
                        this.hCnt = res.data.clientCnt.hcnt
                        this.eCnt = res.data.clientCnt.ecnt

                        this.reservationList = res.data.reservationList
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.reservationList = []
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(()=>{
                    this.loading = false;
                })
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    } else {
                        alert('센터리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },

        /**
         * @description: 상담진행 리스트 팝업 데이터 세팅
         * @param {Object} 상담통계 아이템
         */
        setConsultProgress(resItem){
            this.parentOnModal = !this.parentOnModal;
            this.parentStaffNm = resItem.staffName;
            this.parentStaffId = resItem.idxCrmStaff;
        }

    }
}
</script>
