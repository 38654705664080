<template>
  <transition name="modal" appear>
    <div v-if="onModal" class="modal modal-overlay" @click.self="handleWrapperClick">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <p class="bold dp-inblock h-30px font-23">{{ consultStaffName }} 상담진행 리스트</p>
          <div class="date">
            <p>
              기간 : {{ cousultStartDate }} ~ {{ cousultEndDate }}
            </p>
          </div>

          <div class="con">
            <div class="con_table mt-20">
              <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00 w-100per">
                <tbody v-if="loading">
                  <tr>
                     <th>상담날짜</th>
                     <th>고객 명</th>
                     <th>상담구분</th>
                     <th>상담유형</th>
                   </tr>
                    <tr>
                     <td colspan="4">검색중입니다.</td>
                   </tr>
                </tbody>
                <tbody v-else>
                   <tr>
                     <th>상담날짜</th>
                     <th>고객 명</th>
                     <th>상담구분</th>
                     <th>상담유형</th>
                   </tr>
                   <tr v-for="(item, index) of clientList" :key="index">
                      <td >{{item.consDate}}</td>
                      <td >{{item.name}}</td>
                      <td >{{item.gubun}}</td>
                      <td >{{item.typeName}}</td>
                   </tr>
                   <tr v-if="clientList.length === 0">
                     <td colspan="4">검색 결과가 없습니다.</td>
                   </tr>
                </tbody>
              </table>
            </div>
            <div class="con_table">
              <div class="page">
                  <ul>
                    <li>
                        <a class="pointer" @click="prevPaging()"> &lt; </a>
                    </li>
                    <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                        <span class="pointer" style="padding: 8px;" @click="paging(index)">
                            <a :class="{'bold': index === page}">{{index}}</a>
                        </span>
                    </li>
                    <li>
                        <a class="pointer" @click="nextPaging()"> &gt; </a>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    onModal: {
        type: Boolean,
        require: true,
        default: false
    },
    cousultStartDate:{
      type: String
    },
    cousultEndDate:{
      type: String
    },
    consultStaffName: {
      type: String
    },
    consultStaffId:{
      type: Number,
      require: true,
      default: -1
    }
  },
  data: ()=>({
      clientList:[],
      loading: false, //로딩
      page: 1,
      pageSize: 10,

      //페이징 변수
      listLength: 0, // 리스트 size
      maxPagingCount: 1, // 최대 페이지 수
      maxPagePart: 1, // 최대 파트가 몇번째인지
      pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
  }),
  watch:{
    onModal(newVal){
      if(newVal === true){
        this.getCounselorList();
      }
    }
  },
  mounted(){
    
  },
  methods: {

    // 이전 페이징
    prevPaging() {
        if (this.page === 1) {
            return false
        } else {
            this.page -= 1
            this.getCounselorList()
        }
    },


    paging(index) {
        this.page = index
        this.getCounselorList()
    },

    // 다음 페이징
    nextPaging() {
        if (this.page === this.maxPagingCount) {
            return false
        } else {
            this.page += 1
            this.getCounselorList()
        }
    },


    handleWrapperClick() {
      this.$emit('update:onModal', false);
    },
    getCounselorList(){
      const params = {
         pageNum: 1,
         pageSize: this.pageSize,
         consStartDate: this.cousultStartDate,
         consEndDate: this.cousultEndDate,
         idxCrmStaff : this.consultStaffId,
         reservStatus: '입실|NS'
      }
      this.axios.get('/api/v1/client/counselor/list',{
        params: params
      })
      .then(res => {
        if(res.data.err === 0){
          this.clientList = res.data.clientList;

          //페이징 처리
          this.listLength = res.data.clientListCount;
          this.maxPagingCount = Math.ceil(this.listLength / this.pageSize);
          this.maxPagePart = Math.ceil(this.maxPagingCount / 10);
          this.pagePart = Math.ceil(this.page / 10);
          // this.calcMaxPagingPop();
        }
        else{
          alert(res.data.result);
          this.clientList = [];
        }
      }).catch(err => {
          console.error(`getCounselorList : `, err);
          this.clientList = [];
          alert(`내담자 조회 오류`);
      })
    },

    // 현재 페이지의 페이지 범위 계산
    calcMaxPaging() {
     
        if (this.maxPagingCount > this.pagePart * 10) {
            this.maxPagePart = this.pagePart * 10
        } else {
            this.maxPagePart = this.maxPagingCount
        }
        var pagingArray = []
        for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
            pagingArray.push(i)
        }
        return pagingArray
    },
    
  }
}
</script>

<style lang="scss" scoped>
.modal {
    .modal-content{
      padding: 20px !important;
    }

    &.modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 30;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    &-window {
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
    }

    &-content {
        width: 600px;
        padding: 10px 10px;
        background: #fff;

        .date{
          margin-top: 20px;
          text-align: left;
        }
    }

    &-footer {
        padding: 10px;
        text-align: right;
    }
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.4s;

    .modal-window {
        transition: opacity 0.4s, transform 0.4s;
    }
}

.modal-leave-active {
    transition: opacity 0.4s ease 0.2s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;

    .modal-window {
        opacity: 0;
        transform: translateY(-20px);
    }
}
</style>